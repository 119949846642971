@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap);
@font-face {
  font-family: apple;
  src: url(/static/media/SF-UI-Display-Regular.696eab33.otf);
}
@font-face {
  font-family: "Constantia";
  src: url("//db.onlinewebfonts.com/t/0b9856633d4311a19df074ea509d8390.eot");
}
html,
body {
  padding: 0;
  margin: 0;
  /* font-family: "Roboto", sans-serif !important; */
  font-family: apple !important;
}

* {
  box-sizing: border-box;
  margin: 0;
}

